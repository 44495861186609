exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advantages-js": () => import("./../../../src/pages/Advantages.js" /* webpackChunkName: "component---src-pages-advantages-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/Archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-area-js": () => import("./../../../src/pages/Area.js" /* webpackChunkName: "component---src-pages-area-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/Documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-edu-standarts-js": () => import("./../../../src/pages/EduStandarts.js" /* webpackChunkName: "component---src-pages-edu-standarts-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/Education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/Feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-finances-js": () => import("./../../../src/pages/Finances.js" /* webpackChunkName: "component---src-pages-finances-js" */),
  "component---src-pages-for-applicants-js": () => import("./../../../src/pages/ForApplicants.js" /* webpackChunkName: "component---src-pages-for-applicants-js" */),
  "component---src-pages-for-parents-js": () => import("./../../../src/pages/ForParents.js" /* webpackChunkName: "component---src-pages-for-parents-js" */),
  "component---src-pages-for-students-js": () => import("./../../../src/pages/ForStudents.js" /* webpackChunkName: "component---src-pages-for-students-js" */),
  "component---src-pages-for-teachers-js": () => import("./../../../src/pages/ForTeachers.js" /* webpackChunkName: "component---src-pages-for-teachers-js" */),
  "component---src-pages-grants-js": () => import("./../../../src/pages/Grants.js" /* webpackChunkName: "component---src-pages-grants-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-js": () => import("./../../../src/pages/International.js" /* webpackChunkName: "component---src-pages-international-js" */),
  "component---src-pages-main-information-js": () => import("./../../../src/pages/MainInformation.js" /* webpackChunkName: "component---src-pages-main-information-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/Map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-materials-js": () => import("./../../../src/pages/Materials.js" /* webpackChunkName: "component---src-pages-materials-js" */),
  "component---src-pages-paid-services-js": () => import("./../../../src/pages/PaidServices.js" /* webpackChunkName: "component---src-pages-paid-services-js" */),
  "component---src-pages-personal-data-js": () => import("./../../../src/pages/PersonalData.js" /* webpackChunkName: "component---src-pages-personal-data-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/Science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-scolarships-js": () => import("./../../../src/pages/Scolarships.js" /* webpackChunkName: "component---src-pages-scolarships-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/SiteMap.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-structure-js": () => import("./../../../src/pages/Structure.js" /* webpackChunkName: "component---src-pages-structure-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/Teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-vacancies-js": () => import("./../../../src/pages/Vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */)
}

