/* eslint-disable */
import "./src/css/style.css";
// import "./src/css/bvi.css";
// import "./src/css/bvi.min.css";
// import "./src/css/example.css";
const $ = require("jquery");

export const onInitialClientRender = () => {
  $(document).ready(function () {
    console.log("The answer is don't think about it!");
  });
};
